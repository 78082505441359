<template>
  <y-layout-body-menu>
    <el-card style="min-height: 45rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form label-position="top">
            <el-form-item label="注册协议">
              <YEditor eid="login_agree" v-model="form.login_agree"></YEditor>
            </el-form-item>
            <el-form-item label="用户协议">
              <YEditor eid="member_agree" v-model="form.member_agree"></YEditor>
            </el-form-item>
            <el-form-item label="隐私条款">
              <YEditor eid="privacy_agree" v-model="form.privacy_agree"></YEditor>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form>
            <el-form-item label="邀请关系失效机制">
              <el-input-number v-model="form.invite_expire"></el-input-number>
              小时
              <div class="y-desc" v-if="form.invite_expire > 0">
                自绑定邀请关系起超过{{ form.invite_expire }}小时，且未购买过免单卡或商城任意商品 <br>
                访问其他人分享链接 且分享人不在自身下级关系链中 重新绑定邀请关系 失效期限重新计时
              </div>
              <div class="y-desc" v-else>当前未开启</div>
            </el-form-item>
            <el-form-item label="订单超时关闭时间">
              <el-input-number v-model="form.order_expire"></el-input-number>分钟
            </el-form-item>
            <el-form-item label="订单发件人手机号">
              <el-input v-model="form.consignor_phone"></el-input>
            </el-form-item>
            <el-form-item label="首页公众号绑定、关注引导">
              <el-switch v-model="form.home_wechat_official_tips"></el-switch>
            </el-form-item>
            <el-form-item label="微信提现">
              <el-switch v-model="form.withdrawal_wechat"></el-switch>
            </el-form-item>
            <el-form-item label="提现手续费">
              <el-input-number v-model="form.withdrawal_fee"></el-input-number>
              %
            </el-form-item>
            <el-form-item label="提现最小金额">
              <el-input-number v-model="form.withdrawal_min"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="海报跳转域名">
              <el-input v-model="form.poster_domain_jump"></el-input>
              <div style="color: red" class="y-desc">注：开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
            </el-form-item>
            <el-form-item label="海报落地域名">
              <el-input v-model="form.poster_domain_landing"></el-input>
              <div style="color: red" class="y-desc">注：开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
            </el-form-item>
            <el-form-item label="腾讯地图key">
              <el-input v-model="form.tx_map_key"></el-input>
            </el-form-item>

            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </y-layout-body-menu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
import YEditor from "@/components/y-form/y-editor/y_editor";

export default {
  name: "info",
  components: {
    YEditor,
    YLayoutBodyMenu,
  },
  data() {
    return {
      form: {
        invite_expire: 0,

        home_wechat_official_tips: false,

        withdrawal_min: 0,
        withdrawal_fee: 0,
        //微信提现开关
        withdrawal_wechat: false,

        poster_domain_landing: "",
        poster_domain_jump: "",
        tx_map_key: "",

        //关于我们页面协议
        login_agree: "",
        member_agree: "",
        privacy_agree: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.system.setting().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.system.settingEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>